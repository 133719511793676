<!-- showColumn -->
<template>
  <div class="showColumn">
    <el-form :model="ruleForm" label-width="160px">
      <el-form-item label="是否显示论文栏目：" prop="showColumn" class="fl">
        <el-radio-group v-model="ruleForm.showColumn">
          <el-radio :label="1">是</el-radio>
          <el-radio :label="0">否</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'showColumn',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
