<template>
  <div class="basicSettings">
    <div class="edits updata">
      <el-form ref="ruleForm" :model="ruleForm" :rules="rules" label-width="160px" class="demo-ruleForm">
        <el-form-item label="模板风格：" class="template" size="zdy3" style="width:1000px;" prop="templateStyleId">
          <el-carousel ref="carousel" :autoplay="false" :loop="false" :initial-index="initialIndex" arrow="hover" indicator-position="none" height="236px" class="cars">
            <el-carousel-item v-for="(item, index) in Math.ceil(templateData.length / 5)" :key="index">
              <div class="cars_div">
                <dl v-for="radioItem in templateData.slice((item - 1) * 5, item * 5)" :key="radioItem.id" @click="getradio(radioItem.id)">
                  <dt class="clearfix">
                    <div class="img-container">
                      <el-image :src="`${GetPictureStream}?pictureId=${radioItem.thumbnailId}&type=small`" class="fl chooseImg" alt="" />
                    </div>
                  </dt>
                  <dd>
                    <el-radio v-model="ruleForm.templateStyleId" :label="radioItem.id" :title="radioItem.name">{{ radioItem.name }}</el-radio>
                  </dd>
                </dl>
              </div>
            </el-carousel-item>
          </el-carousel>
        </el-form-item>
        <el-form-item prop="language" label="模板语种：" class="zdy2">
          <el-select v-model="ruleForm.language" placeholder="请选择" @change="getConfigModalList">
            <el-option v-for="item in languageList" :key="item.key" :label="item.value" :value="item.key" />
          </el-select>
        </el-form-item>
        <component :is="currentTemplateComList[currentTemplateSetType]" :ruleForm="ruleForm" :configInfo="configInfo"></component>
      </el-form>
    </div>
  </div>
</template>

<script>
import { GetConfigModals, GetPictureStream, UpdateConfig, GetDefaultByPushTemplateStyleId } from '@/api/configManage'
import { mapGetters } from 'vuex'
import Editor from '@tinymce/tinymce-vue'
import setType11Template from './templates/setType11Template.vue'
import setType6Template from './templates/setType6Template.vue'
import setType5Template from './templates/setType5Template.vue'
import setType4Template from './templates/setType4Template.vue'
import setType3Template from './templates/setType3Template.vue'
import setType2Template from './templates/setType2Template.vue'
import setType1Template from './templates/setType1Template.vue'
export default {
  name: 'basicSettings',
  components: {
    Editor
  },
  computed: {
    ...mapGetters({
      platMagId: 'platMagId',
      platTenantId: 'platTenantId',
      magId: 'magId'
    })
  },
  watch: {
    magId() {
    },
    'ruleForm.templateStyleId'(n) {
      this.changeModule(n)
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    rules: {
      type: Object,
      default: () => {
        return {}
      }
    },
    status: {
      type: String,
      default: ''
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      GetPictureStream: GetPictureStream,
      edit: true, // 展示还是编辑
      templateData: [],
      currentTemplateIndex: '',
      currentTemplateSetType: '',
      languageList: [
        {
          key: 1,
          value: '中文'
        },
        {
          key: 2,
          value: '英文'
        }
      ],
      isClear: false,
      detail: '',
      initialIndex: 0,
      currentTemplateComList: {
        1: setType1Template,
        2: setType2Template,
        3: setType3Template,
        4: setType4Template,
        5: setType5Template,
        6: setType5Template,
        11: setType11Template
      }
    }
  },
  mounted() {
    this.getConfigModalList()
  },
  methods: {
    // 提交修改
    handleSubmit() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          // 提交
          const params = {
            id: this.ruleForm.id,
            bannerId: this.ruleForm.bannerId,
            templateStyleId: this.ruleForm.templateStyleId,
            language: this.ruleForm.language,
            textBefore: this.ruleForm.textBefore,
            textBeforeTitle: this.ruleForm.textBeforeTitle,
            textAfter: this.ruleForm.textAfter,
            publicationId: this.platMagId,
            tenantId: this.platTenantId,
            magId: this.magId,
            footerId: this.ruleForm.footerId,
            colourConfig: this.ruleForm.colourConfig,
            colourConfigOther: this.ruleForm.colourConfigOther,
            column: this.ruleForm.column,
            enColumn: this.ruleForm.enColumn,
            magCoverId: this.ruleForm.magCoverId,
            topic: this.ruleForm.topic, // 论文主题
            specialTopic: this.ruleForm.specialTopic2, // 导读标题
            specialContent: this.ruleForm.specialContent, // 导读内容
            qrCodeId: this.ruleForm.qrCodeId, // 公众号二维码
            contactUs: this.ruleForm.contactUs, // 联系我们
            onlineSubmission: this.ruleForm.onlineSubmission, // 在线投稿
            currentDiscuss: this.ruleForm.currentDiscuss, // 整期目次
            backIssueBrowsing: this.ruleForm.backIssueBrowsing, // 过刊浏览
            showColumn: this.ruleForm.showColumn, // 是否显示论文栏目
            publishPushContent: this.ruleForm.publishPushContent, //是否开启发文精准推送  0 否 1是
            referencePushContent: this.ruleForm.referencePushContent //是否开启引文精准推送  0 否 1是
          }
          UpdateConfig(params).then((res) => {
            if (res && res.data && res.data.status === 0) {
              this.$message.success('修改成功')
            }
          })
        } else {
          document.getElementById('scrollBox').scrollIntoView()
        }
      })
    },
    // 获取模板配置列表
    getConfigModalList() {
      const params = {
        language: this.ruleForm.language
      }
      GetConfigModals(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          const { data } = res.data
          const { content = [] } = data
          this.templateData = content
          const findItem = this.templateData.find((item, index) => {
            if (item.id === this.ruleForm.templateStyleId) {
              this.initialIndex = index
            }
            return item.id === this.ruleForm.templateStyleId
          })
          this.$refs.carousel.setActiveItem(this.initialIndex)
          if (!findItem) {
            this.$set(this.ruleForm, 'templateStyleId', this.templateData.length > 0 ? this.templateData[0].id : '')
          }
          if (this.ruleForm.templateStyleId) {
            this.changeModule(this.ruleForm.templateStyleId)
          }
        }
      })
    },
    getradio(val) {
      // 模板风格选择
      this.$set(this.ruleForm, 'templateStyleId', val)
    },
    changeModule(id) {
      if (id) {
        const params = {
          pushTemplateStyleId: id
        }
        GetDefaultByPushTemplateStyleId(params).then((res) => {
          if (res && res.data && res.data.status === 0) {
            const { data } = res.data
            if (data) {
              this.ruleForm.specialTopic2 = data.specialTopic
              this.ruleForm.textBeforeTitle = data.textBeforeTitle
            } else {
              this.ruleForm.specialTopic2 = ''
              this.ruleForm.textBeforeTitle = ''
            }
            if (this.templateData.length > 0) {
              this.templateData.map((item, index) => {
                if (item.id === id) {
                  this.currentTemplateIndex = index
                  this.currentTemplateSetType = item.setType
                  this.$emit('getModuleType', item.setType)
                }
              })
            }
          }
        })
      }
    }
  }
}
</script>
<style lang="scss">
.basicSettings {
  .edits .el-image__preview {
    object-fit: contain;
  }
  .template {
    .el-form-item__error {
      left: 0;
      bottom: -30px;
      top: auto;
    }
  }
  .cars_div {
    dd {
      label {
        display: inline;
      }
    }
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 14px !important;
  }
}
</style>
<style scoped lang="scss">
.basicSettings {
  .betterSize {
    margin-left: 30px;
  }
  .edits {
    padding: 40px 69px 120px;
    position: relative;
    .cars {
      border: 1px solid #ddd;
      background: #fff;
      .cars_div {
        padding: 15px;
        display: flex;
        dl {
          cursor: pointer;
          margin-right: 30px;
        }
        dd {
          overflow: hidden;
          width: 140px;
          text-overflow: ellipsis;
          white-space: nowrap;
          word-break: break-all;
        }
      }
    }
  }
}
.zdy_img {
  min-height: 100px;
  border: 1px solid #ddd;
  background: #f4f4f4;
  overflow: hidden;
  position: relative;
  img {
    height: auto;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .upload-demo {
    text-align: left;
    line-height: 0;
  }
}
.wechatCover {
  width: 150px;
  height: 150px;
}
.cover_img img,
.code_img img {
  width: 120px;
}
.img-container {
  display: flex;
  width: 140px;
  height: 180px;
}
.chooseImg {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  height: 100%;
  object-fit: contain;
  margin: auto;
}
.postion2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
}
.upload-cover {
  .postion2 {
    height: 231px;
    width: 156px;
  }
}
.tinyCover {
  width: 100px;
  height: 100px;
}
.wechatBgCover {
  width: 146px;
  height: 220px;
}
.el-upload__tip {
  float: left;
  padding-left: 20px;
  color: #999;
  font-size: 14px;
}
.content_box {
  margin-bottom: 50px;
}
.magCover_box {
  .el-upload__tip {
    margin: 0;
    padding: 0;
    text-align: left;
  }
}
</style>
