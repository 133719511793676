<!-- setType6Template -->
<template>
  <div class="setType6Template">
    <el-form :model="ruleForm" label-width="160px">
      <textBeforeTitle :ruleForm="ruleForm"></textBeforeTitle>
      <textBefore :ruleForm="ruleForm"></textBefore>
      <magCover :ruleForm="ruleForm"></magCover>
      <specialTopic :ruleForm="ruleForm"></specialTopic>
      <specialContent :ruleForm="ruleForm"></specialContent>
      <topic :ruleForm="ruleForm"></topic>
      <colourConfig :ruleForm="ruleForm"></colourConfig>
      <showColumn :ruleForm="ruleForm"></showColumn>
    </el-form>
  </div>
</template>

<script>
import textBeforeTitle from '../modules/textBeforeTitle.vue'
import textBefore from '../modules/textBefore.vue'
import magCover from '../modules/magCover.vue'
import specialTopic from '../modules/specialTopic.vue'
import specialContent from '../modules/specialContent.vue'
import topic from '../modules/topic.vue'
import colourConfig from '../modules/colourConfig.vue'
import showColumn from '../modules/showColumn.vue'
export default {
  name: 'setType6Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    textBeforeTitle,
    textBefore,
    magCover,
    specialTopic,
    specialContent,
    topic,
    colourConfig,
    showColumn
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
