<!-- setType1Template -->
<template>
  <div class="setType1Template">
    <el-form :model="ruleForm" label-width="160px">
      <textBefore :ruleForm="ruleForm"></textBefore>
      <textAfter :ruleForm="ruleForm"></textAfter>
      <!-- <openPublishPush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openReferencePush> -->
      <magCover :ruleForm="ruleForm"></magCover>
      <column :ruleForm="ruleForm"></column>
      <enColumn :ruleForm="ruleForm"></enColumn>
      <banner :ruleForm="ruleForm"></banner>
      <footerItem :ruleForm="ruleForm"></footerItem>
      <colourConfig :ruleForm="ruleForm"></colourConfig>
      <colourConfigOther :ruleForm="ruleForm"></colourConfigOther>
      <showColumn :ruleForm="ruleForm"></showColumn>
    </el-form>
  </div>
</template>

<script>
import textBefore from '../modules/textBefore.vue'
import textAfter from '../modules/textAfter.vue'
import magCover from '../modules/magCover.vue'
import column from '../modules/column.vue'
import enColumn from '../modules/enColumn.vue'
import banner from '../modules/banner.vue'
import footerItem from '../modules/footerItem.vue'
import colourConfig from '../modules/colourConfig.vue'
import colourConfigOther from '../modules/colourConfigOther.vue'
import showColumn from '../modules/showColumn.vue'
// import openPublishPush from '../modules/openPublishPush.vue'
// import openReferencePush from '../modules/openReferencePush.vue'

export default {
  name: 'setType1Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    textBefore,
    textAfter,
    magCover,
    column,
    enColumn,
    banner,
    footerItem,
    colourConfig,
    colourConfigOther,
    showColumn
    // openPublishPush,
    // openReferencePush
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
