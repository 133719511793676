<!-- setType3Template -->
<template>
  <div class="setType3Template">
    <el-form :model="ruleForm" label-width="160px">
      <banner :ruleForm="ruleForm"></banner>
      <textBefore :ruleForm="ruleForm"></textBefore>
      <footerItem :ruleForm="ruleForm"></footerItem>
      <textAfter :ruleForm="ruleForm"></textAfter>
    </el-form>
  </div>
</template>

<script>
import banner from '../modules/banner.vue'
import textBefore from '../modules/textBefore.vue'
import footerItem from '../modules/footerItem.vue'
import textAfter from '../modules/textAfter.vue'
export default {
  name: 'setType3Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: { banner, textBefore, footerItem, textAfter },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
