<!-- column -->
<template>
  <div class="column">
    <el-form :model="ruleForm" label-width="160px">
      <el-form-item label="栏目名称：" prop="column" style="width: 50%;" id="scrollBox">
        <el-input v-model="ruleForm.column" type="text" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'column',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
