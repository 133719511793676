<!-- currentDiscuss -->
<template>
  <div class="currentDiscuss">
    <el-form :model="ruleForm" label-width="160px">
      <el-form-item label="整期目次：" prop="currentDiscuss">
        <el-input v-model="ruleForm.currentDiscuss" type="text" class="fl" style="width: 50%;" />
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'currentDiscuss',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
