<!-- setType2Template -->
<template>
  <div class="setType2Template">
    <el-form :model="ruleForm">
      <textBefore :ruleForm="ruleForm"></textBefore>
      <!-- <openPublishPush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush v-if="configInfo.openPublishPush" :ruleForm="ruleForm"></openReferencePush> -->
      <magCover :ruleForm="ruleForm"></magCover>
      <specialTopic :ruleForm="ruleForm"></specialTopic>
      <specialContent :ruleForm="ruleForm"></specialContent>
      <topic :ruleForm="ruleForm"></topic>
      <banner :ruleForm="ruleForm"></banner>
      <footerItem :ruleForm="ruleForm"></footerItem>
      <colourConfig :ruleForm="ruleForm"></colourConfig>
      <qrCode :ruleForm="ruleForm"></qrCode>
      <colourConfigOther :ruleForm="ruleForm"></colourConfigOther>
      <contactUs :ruleForm="ruleForm"></contactUs>
      <onlineSubmission :ruleForm="ruleForm"></onlineSubmission>
      <currentDiscuss :ruleForm="ruleForm"></currentDiscuss>
      <backIssueBrowsing :ruleForm="ruleForm"></backIssueBrowsing>
      <showColumn :ruleForm="ruleForm"></showColumn>
    </el-form>
  </div>
</template>

<script>
import textBefore from '../modules/textBefore.vue'
import magCover from '../modules/magCover.vue'
import specialTopic from '../modules/specialTopic.vue'
import specialContent from '../modules/specialContent.vue'
import topic from '../modules/topic.vue'
import banner from '../modules/banner.vue'
import footerItem from '../modules/footerItem.vue'
import colourConfig from '../modules/colourConfig.vue'
import colourConfigOther from '../modules/colourConfigOther.vue'
import qrCode from '../modules/qrCode.vue'
import contactUs from '../modules/contactUs.vue'
import onlineSubmission from '../modules/onlineSubmission.vue'
import currentDiscuss from '../modules/currentDiscuss.vue'
import backIssueBrowsing from '../modules/backIssueBrowsing.vue'
import showColumn from '../modules/showColumn.vue'
// import openPublishPush from '../modules/openPublishPush.vue'
// import openReferencePush from '../modules/openReferencePush.vue'
export default {
  name: 'setType2Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    textBefore,
    magCover,
    specialTopic,
    specialContent,
    topic,
    banner,
    footerItem,
    colourConfig,
    colourConfigOther,
    qrCode,
    contactUs,
    onlineSubmission,
    currentDiscuss,
    backIssueBrowsing,
    showColumn
    // openPublishPush,
    // openReferencePush
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
