<!-- setType11Template -->
<template>
  <div class="setType11Template">
    <el-form :model="ruleForm" label-width="160px">
      <textBefore :ruleForm="ruleForm"></textBefore>
      <textAfter :ruleForm="ruleForm"></textAfter>
      <!-- <openPublishPush :ruleForm="ruleForm"></openPublishPush> -->
      <!-- <openReferencePush :ruleForm="ruleForm"></openReferencePush> -->
      <colourConfig :ruleForm="ruleForm"></colourConfig>
    </el-form>
  </div>
</template>

<script>
import textBefore from '../modules/textBefore.vue'
import textAfter from '../modules/textAfter.vue'
import colourConfig from '../modules/colourConfig.vue'
// import openPublishPush from '../modules/openPublishPush.vue'
// import openReferencePush from '../modules/openReferencePush.vue'
export default {
  name: 'setType11Template',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    },
    configInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {
    textBefore,
    textAfter,
    colourConfig
    // openPublishPush,
    // openReferencePush
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
