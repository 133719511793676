import { render, staticRenderFns } from "./onlineSubmission.vue?vue&type=template&id=f409fc22&scoped=true"
import script from "./onlineSubmission.vue?vue&type=script&lang=js"
export * from "./onlineSubmission.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f409fc22",
  null
  
)

export default component.exports