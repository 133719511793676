<!-- colourConfigOther -->
<template>
  <div class="colourConfigOther">
    <el-form :model="ruleForm" label-width="160px">
      <el-form-item label="序号及跳转文案颜色：" size="zdy7" style="width:1000px; text-align: left" prop="colourConfigOther">
        <el-color-picker v-model="ruleForm.colourConfigOther" color-format="hex"></el-color-picker>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
export default {
  name: 'colourConfigOther',
  data() {
    return {}
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped></style>
