<!-- footer -->
<template>
  <div class="footer">
    <el-form :model="ruleForm" label-width="160px">
      <el-form-item label="footer图片：" size="zdy4" style="width:1000px;" prop="footerId">
        <div style="position: relative; overflow: hidden">
          <div class="zdy_img">
            <img class="fl" :src="footerImgSrc" alt />
          </div>
          <el-upload :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" class="upload-cover" :auto-upload="true" :on-success="handleFooterSuccess">
            <el-button class="postion2" size="small" type="primary">上传图片</el-button>
          </el-upload>
          <div slot="tip" class="el-upload__tip">
            格式支持：jpg、png、jpeg
            <span class="betterSize">尺寸推荐：812*140</span>
          </div>
        </div>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
export default {
  name: 'footerItem',
  data() {
    return {
      UploadPictureStream: UploadPictureStream,
      GetPictureStream: GetPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      footerImg: ''
    }
  },
  props: {
    ruleForm: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  components: {},
  computed: {
    // footer图
    footerImgSrc() {
      return this.footerImg ? this.footerImg : this.ruleForm.footerId ? `${this.GetPictureStream}?pictureId=${this.ruleForm.footerId}` : this.morImg
    }
  },
  mounted() {},
  methods: {
    //成功上传footer图片
    handleFooterSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.footerImg = URL.createObjectURL(file.raw)
      this.$set(this.ruleForm, 'footerId', ID)
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  .zdy_img {
    min-height: 100px;
    border: 1px solid #ddd;
    background: #f4f4f4;
    overflow: hidden;
    position: relative;
    height: 231px;
    width: 156px;
    float: left;
    img {
      height: auto;
      max-height: 100%;
      max-width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
    }
  }
  .el-upload__tip {
    float: left;
    padding-left: 20px;
    color: #999;
    font-size: 14px;
  }
  .upload-cover {
    .postion2 {
      height: 231px;
      width: 156px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }
}
</style>
