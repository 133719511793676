<!-- emailTemplate -->
<template>
  <div class="w center_all emailTemplate">
    <div class="center_2">
      <ul class="crumbs clearfix">
        <li>邮件推送</li>
        <li>-></li>
        <li>本刊设置</li>
        <li>-></li>
        <li>本刊模板</li>
      </ul>
      <!-- 新建模板按钮 -->
      <el-button class="new-btn fl" size="small" round icon="el-icon-plus" @click="newModule">新建</el-button>
      <!-- 新建编辑模板弹框 -->
      <el-dialog
        :show-close="true"
        :visible.sync="newModuleDialogVisible"
        class="emailTemplate-dialog"
        width="1070px"
        :title="(newEditStatus === 'new' ? '新建' : newEditStatus === 'edit' ? '编辑' : '') + '模板'"
      >
        <el-form ref="ruleForm" :model="ruleForm" :rules="finalRules" label-width="150px" class="demo-ruleForm">
          <el-form-item label="模板名称：" prop="name">
            <el-input v-model="ruleForm.name" type="text" class="fl" style="width: 50%;" />
          </el-form-item>
          <basicSettings ref="basicRuleForm" :ruleForm="ruleForm" :rules="rules" :status="newEditStatus" :configInfo="configInfo" @getModuleType="getModuleType"></basicSettings>
        </el-form>
        <span slot="footer" class="dialog-footer" style="width: 100%">
          <el-button @click="newModuleDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="newEditModuleBtn">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 模板列表 -->
      <div class="module-list-box">
        <el-row :gutter="10" style="margin-top: 15px">
          <el-col v-for="(item, index) in moduleData" :key="item.id" style="max-width: 230px">
            <el-card :body-style="{ padding: '7px' }" class="el-card-item">
              <div class="template-cover-box" @click="templateClick(item, index)">
                <div class="template-cover">
                  <img class="cus-img" :class="item.coverId ? '' : 'defaultImg'" :src="item.templateCover" alt />
                </div>
                <el-upload :action="UploadPictureStream" name="file" :show-file-list="false" accept=".png,.jpg,.jpeg" class="upload-demo" :auto-upload="true" :on-success="handleTemplateCoverSuccess">
                  <el-button class="postion2" size="small" type="primary">上传图片</el-button>
                </el-upload>
                <div v-if="item.isDefault === 1" class="isDefault-tag">默认模板</div>
              </div>
              <div>
                <p class="template-name">{{ item.name }}</p>
                <div class="card-bottom">
                  <el-button type="text" @click="setDefault(item)">
                    {{ item.isDefault === 1 ? '取消默认模板' : '设为默认模板' }}
                  </el-button>
                  <el-button type="text" @click="editItem(item)">编辑</el-button>
                  <el-button type="text" @click="deleteTtem(item)">删除</el-button>
                </div>
              </div>
            </el-card>
          </el-col>
        </el-row>
        <Pagination v-if="moduleData.length > 0" :initObj="initObj" @onChangePageData="handleChangePageData" />
      </div>
      <!-- 删除模版提示 设为默认模板提示 -->
      <el-dialog title="提示" :visible.sync="commonTemplateVisible" width="30%">
        <span>{{ commonDialogText }}</span>
        <span slot="footer" class="dialog-footer">
          <el-button @click="commonTemplateVisible = false">取 消</el-button>
          <el-button type="primary" @click="commonTemplateSure">确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { SaveEmailModuleSettings, GetEmailModuleList, UpdateTemplateCover, DeleteEmailTemplate, UpdateIsDefaulUt, GetEmailConfig } from '@/api/emailPush'
import { GetPictureStream, UploadPictureStream } from '@/api/configManage'
import basicSettings from './components/basicSettings'
import Pagination from '@/components/Pagination'
export default {
  name: 'emailTemplate',
  data() {
    return {
      UploadPictureStream: UploadPictureStream,
      GetPictureStream: GetPictureStream,
      morImg: require('@/assets/img/img_default.png'),
      defaultImg: require('@/assets/img/default.png'),
      newModuleDialogVisible: false,
      ruleForm: {
        name: '', // 模板名称
        bannerId: '', // 模板banner
        templateStyleId: '', // 模板风格
        language: 1, // 模板语种
        textBefore: '', // 文前内容
        textBeforeTitle: '',
        textAfter: '', // 文后内容
        footerId: '', // footer图片
        colourConfig: '', // 配色
        colourConfigOther: '', // 配色
        column: '', // 栏目名称
        enColumn: '', // 英文栏目名称
        magCoverId: '', // 期刊介绍封面
        topic: '', // 论文主题
        specialTopic: '', // 导读标题
        specialContent: '', // 导读内容
        qrCodeId: '', // 公众号二维码
        contactUs: '', // 联系我们
        onlineSubmission: '', // 在线投稿
        currentDiscuss: '', // 整期目次
        backIssueBrowsing: '', // 过刊浏览
        showColumn: 1 // 是否显示论文栏目
        // openPublishPush:
        // '<p><span>尊敬的{AUTHOR_NAME}老师您好！您在{《遥感学报》}投稿的文章已出版！</span></p><ul style="margin: 0px; color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei";"><li>&nbsp;{全球降水观测计划多卫星联合反演降水产品的极端降水监测潜力研究}欢迎浏览阅读。</li></ul><p><span>欢迎浏览阅读。</span></p>', //是否开启发文精准推送  0 否 1是
        // openReferencePush:
        // '<p><span style="color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei"; ">尊敬的{AUTHOR_NAME}老师您好！您曾发表的{Ground observation data set from HL_RS_TS}等，被我刊本期的以下文章进行了引用：</span></p><ul style="margin: 0px; color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei";"><li>&nbsp;{全球降水观测计划多卫星联合反演降水产品的极端降水监测潜力研究}</li></ul><p><span>欢迎浏览阅读。</span></p>' //是否开启引文精准推送  0 否 1是
      },
      rules: {
        name: [{ required: true, trigger: 'blur', message: '请填写模板名称' }],
        language: [{ required: true, trigger: 'change', message: '请选择语言' }],
        bannerId: [{ required: true, trigger: 'change', message: '请上传模板banner' }],
        templateStyleId: [{ required: true, trigger: 'change', message: '请选择模板风格' }]
      },
      rules4: {
        name: [{ required: true, trigger: 'blur', message: '请填写模板名称' }],
        templateStyleId: [{ required: true, trigger: 'change', message: '请选择模板风格' }]
      },
      moduleData: [],
      initObj: {
        page: 1,
        size: 10
      },
      clickTemplateItem: '',
      clickTemplateIndex: '',
      newEditStatus: '',
      commonTemplateVisible: false,
      currentTemplateId: '',
      dialogType: '',
      setType: '',
      configInfo: {}
    }
  },
  props: {},
  components: {
    basicSettings,
    Pagination
  },
  watch: {
    magId() {
      this.getModuleList()
    }
  },
  computed: {
    ...mapGetters({
      magId: 'magId'
    }),
    commonDialogText() {
      return this.dialogType === 'delete' ? '确定删除吗？' : this.dialogType === 'setDefault' ? '确定设为默认模板吗？' : this.dialogType === 'cancelDefault' ? '确定取消默认模板吗？' : ''
    },
    finalRules() {
      let rules = []
      if (this.setType == 4) {
        rules = this.rules4
      } else if (this.setType == 3) {
        rules = {}
      } else {
        rules = this.rules
      }
      return rules
    }
  },
  mounted() {
    this.getModuleList()
    this.getConfig()
  },
  methods: {
    initData() {
      this.ruleForm = {
        name: '', // 模板名称
        bannerId: '', // 模板banner
        templateStyleId: '', // 模板风格
        language: 1, // 模板语种
        textBefore: '', // 文前内容
        textBeforeTitle: '',
        textAfter: '', // 文后内容
        footerId: '', // footer图片
        colourConfig: '', // 配色
        colourConfigOther: '', // 配色
        column: '', // 栏目名称
        enColumn: '', // 英文栏目名称
        magCoverId: '', // 期刊介绍封面
        topic: '', // 论文主题
        specialTopic: '', // 导读标题
        specialContent: '', // 导读内容
        qrCodeId: '', // 公众号二维码
        contactUs: '', // 联系我们
        onlineSubmission: '', // 在线投稿
        currentDiscuss: '', // 整期目次
        backIssueBrowsing: '', // 过刊浏览
        showColumn: 1 // 是否显示论文栏目
        // openPublishPush:
        // '<p><span>尊敬的{AUTHOR_NAME}老师您好！您在{《遥感学报》}投稿的文章已出版！</span></p><ul style="margin: 0px; color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei"; "><li>&nbsp;{全球降水观测计划多卫星联合反演降水产品的极端降水监测潜力研究}欢迎浏览阅读。</li></ul><p><span>欢迎浏览阅读。</span></p>', //是否开启发文精准推送  0 否 1是
        // openReferencePush:
        // '<p><span style="color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei";">尊敬的{AUTHOR_NAME}老师您好！您曾发表的{Ground observation data set from HL_RS_TS}等，被我刊本期的以下文章进行了引用：</span></p><ul style="margin: 0px; color: rgba(51, 51, 51, 0.996); font-family: "Microsoft YaHei"; "><li>&nbsp;{全球降水观测计划多卫星联合反演降水产品的极端降水监测潜力研究}</li></ul><p><span>欢迎浏览阅读。</span></p>' //是否开启引文精准推送  0 否 1是
      }
    },
    // 获取当前配置
    getConfig() {
      let params = {
        magId: this.magId
      }
      GetEmailConfig(params).then((resp) => {
        let { data, status } = resp.data
        if (status !== 0) {
          this.$message({
            type: 'error',
            message: data.message
          })
        } else {
          this.configInfo = { ...data }
        }
      })
    },
    // 新建模板
    newModule() {
      this.initData()
      this.newModuleDialogVisible = true
      this.newEditStatus = 'new'
    },
    // 新建编辑模板确定
    newEditModuleBtn() {
      this.$refs.ruleForm.validate((valid) => {
        if (!valid) {
          let message = ''
          if (!this.ruleForm.name) {
            message = '请填写模板名称'
            this.$message({
              message: message,
              type: 'error'
            })
          }
        } else {
          this.$refs.basicRuleForm.$refs.ruleForm.validate((va) => {
            if (!va) {
              let message = ''
              if (!this.ruleForm.language) {
                message = '请选择模板语种'
              }
              // else {
              // console.log(this.ruleForm.bannerId, 'this.ruleForm.bannerId')
              // if (!this.ruleForm.bannerId) {
              //   message = '请上传模板banner'
              // } else {
              if (!this.ruleForm.templateStyleId) {
                message = '请选择模板风格'
              } else {
                message = '请填写正确的模板信息'
              }
              // }
              // }
              this.$message({
                message: message,
                type: 'error'
              })
            } else {
              let params = { magId: this.magId, ...this.ruleForm }
              SaveEmailModuleSettings(params).then((res) => {
                if (res && res.data && res.data.status === 0) {
                  let message = this.newEditStatus === 'new' ? '新建' : '编辑'
                  this.$message.success(message + '模板成功')
                  this.newModuleDialogVisible = false
                  this.getModuleList()
                }
              })
            }
          })
        }
      })
    },
    // 获取模板列表
    getModuleList() {
      let params = {
        magId: this.magId,
        page: this.initObj.page - 1,
        size: this.initObj.size
      }
      GetEmailModuleList(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          let { data } = res.data
          if (data) {
            this.moduleData = data.content
            if (this.moduleData.length > 0) {
              this.moduleData.map((item, index) => {
                if (item.coverId) {
                  this.$set(this.moduleData[index], 'templateCover', `${GetPictureStream}?pictureId=${item.coverId}`)
                } else {
                  this.$set(this.moduleData[index], 'templateCover', this.morImg)
                }
              })
            }
          }
        }
      })
    },
    // 页码改变了之后的操作
    handleChangePageData(value) {
      this.initObj.page = value.page
      this.initObj.size = value.size
      this.currentPage = value.page
      this.getModuleList()
    },
    // 点击每个模板
    templateClick(item, index) {
      this.clickTemplateItem = item
      this.clickTemplateIndex = index
    },
    //模板封面上传成功后
    handleTemplateCoverSuccess(res, file) {
      const { data } = res
      const { ID } = data
      this.$set(this.moduleData[this.clickTemplateIndex], 'templateCover', URL.createObjectURL(file.raw))
      const params = {
        coverId: ID,
        id: this.clickTemplateItem.id
      }
      UpdateTemplateCover(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success('上传成功')
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
      })
    },
    // 编辑模板
    editItem(item) {
      this.newModuleDialogVisible = true
      this.newEditStatus = 'edit'
      this.ruleForm = { ...item }
    },
    // 删除模板
    deleteTtem(item) {
      this.dialogType = 'delete'
      this.commonTemplateVisible = true
      this.currentTemplateId = item.id
    },
    // 设为默认模板
    setDefault(item) {
      if (item.isDefault === 1) {
        this.dialogType = 'cancelDefault'
      } else {
        this.dialogType = 'setDefault'
      }
      this.commonTemplateVisible = true
      this.currentTemplateId = item.id
    },
    // 删除模板确定
    commonTemplateSure() {
      let params = {}
      let url = ''
      if (this.dialogType === 'delete') {
        // 删除模板
        params = {
          id: this.currentTemplateId
        }
        url = DeleteEmailTemplate
      } else if (this.dialogType === 'setDefault') {
        // 设为默认模板
        params = {
          id: this.currentTemplateId,
          isDefaule: 1
        }
        url = UpdateIsDefaulUt
      } else if (this.dialogType === 'cancelDefault') {
        // 取消默认模板
        params = {
          id: this.currentTemplateId,
          isDefaule: 0
        }
        url = UpdateIsDefaulUt
      }
      url(params).then((res) => {
        if (res && res.data && res.data.status === 0) {
          this.$message.success(res.data.message)
        } else {
          const { message = '' } = res.data
          this.$message.error(message)
        }
        // 刷新列表
        this.getModuleList()
        this.commonTemplateVisible = false
      })
    },
    getModuleType(type) {
      this.setType = type
    }
  }
}
</script>
<style lang="scss">
.emailTemplate {
  .basicSettings {
    .edits {
      padding: 0;
    }
  }
  .template-cover-box {
    .el-upload {
      width: 100%;
      height: 100%;
    }
  }
  .el-dialog__wrapper {
    padding: 0;
  }
}
</style>
<style lang="scss" scoped>
.emailTemplate {
  .center_2 {
    min-height: 400px;
  }
  .el-card-item {
    padding: 0;
    margin-bottom: 10px;
    .template-cover-box {
      position: relative;
      .template-cover {
        width: 100%;
        max-width: 235px;
        height: 188px;
        display: block;
        position: relative;
        text-align: center;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
      .upload-demo {
        width: 100%;
        height: 188px;
        position: absolute;
        top: 0;
        opacity: 0;
      }
    }
    .template-name {
      margin-top: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      word-break: break-all;
      text-align: left;
    }
    .card-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      button {
        text-indent: 0;
        top: 0;
        margin-left: 0;
      }
    }
  }
  .module-list-box {
    margin-top: 50px;
    .isDefault-tag {
      position: absolute;
      top: -7px;
      right: -7px;
      width: 80px;
      height: 28px;
      line-height: 28px;
      background: #de882f;
      border-radius: 20px 0 0 20px;
      color: #fff;
      text-align: center;
    }
  }
  .emailTemplate-dialog {
    z-index: 1 !important;
  }
}
</style>
